import React from 'react';
import { CardIcon } from '@latitude/card-icon';
import { Heading4 } from '@latitude/heading';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FONT_SIZE,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';

import imageShowCare from '../../images/be-you.webp';
import imageBeCurious from '../../images/be-better.webp';
import imageActRight from '../../images/be-our-future.webp';
import styled from 'styled-components';

const CardCol = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    width: 22%;
  }
`

const LifeAtLatitude = () => {
  return (
    <Section className="w-100" css={`background-color: #F8F8F8;`}>
      <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER} marginTop={MARGIN.M32}>
        Life @ Latitude
      </Heading4>
      <Text
        align={ALIGN.CENTER}
        fontSize={FONT_SIZE.LARGE}
      >
        Latitude is the scope for freedom of action or thought.
      </Text>
      <Text
        align={ALIGN.CENTER}
        fontSize={FONT_SIZE.LARGE}
        marginBottom={MARGIN.M24}
      >
        It allows you to define your own path!
      </Text>
      <Text
        align={ALIGN.CENTER}
        fontSize={FONT_SIZE.LARGE}
      >
        What if you could BE YOU?
      </Text>
      <Text
        align={ALIGN.CENTER}
        fontSize={FONT_SIZE.LARGE}
      >
        What if you could BE BETTER?
      </Text>
      <Text
        align={ALIGN.CENTER}
        fontSize={FONT_SIZE.LARGE}
        marginBottom={MARGIN.M48}
      >
        What if you could BE OUR FUTURE?
      </Text>
      <div
        className="row"
        css={`justify-content: center; gap: 40px;`}
      >
        <CardCol>
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px' }}
                src={imageShowCare}
                alt="Be YOU"
              />
            }
            title="Be YOU"
          >
            <Text align={ALIGN.CENTER}>
              We want you to bring your whole self, the authentic YOU without fear of judgement.
              Be part of a culture that embraces your can-do attitude, that gives you the freedom
              and flexibility to unleash your creative genius and make it happen. When you achieve
              you will be rewarded and recognised for your efforts.
            </Text>
          </CardIcon>
        </CardCol>
        <CardCol className="">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px' }}
                src={imageBeCurious}
                alt="Be BETTER"
              />
            }
            title="Be BETTER"
          >
            <Text align={ALIGN.CENTER}>
              We want you to be BETTER at everything you do! Set high standards and be passionate
              about achieving  extraordinary customer outcomes. Take charge of your own development
              and encourage others to develop and never stop learning. Most important, be brave and
              be better, look outwards and lead the way to the future. Be curious and prepared to
              learn and be challenged every day!
            </Text>
          </CardIcon>
        </CardCol>
        <CardCol className="">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px' }}
                src={imageActRight}
                alt="Be OUR FUTURE"
              />
            }
            title="Be OUR FUTURE"
          >
            <Text align={ALIGN.CENTER}>
              Help us build OUR FUTURE by spending time on meaningful work - reimagining opportunities
              for our customers, creating something bigger and better. We are big enough to make an
              impact in the market place and small enough to be nimble and courageous. We are encouraged
              to take risks where we succeed or we fail, but it is our customer obsession and our curiosity
              that keeps the magic happening.
            </Text>
          </CardIcon>
        </CardCol>
      </div>
    </Section>
  );
};

export default LifeAtLatitude;
