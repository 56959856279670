import React from 'react';
import { Heading4 } from '@latitude/heading';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import {
  ALIGN,
  COLOR,
  FONT_SIZE,
  MARGIN
} from '@latitude/core/utils/constants';

const OurStorySection = () => {
  return (
    <Section css={`background-color: #F8F8F8; padding-bottom: 64px;`}>
      <div className="row justify-content-center">
        <div className="col-md-10 text-left">
          <Vertical spacing={MARGIN.M24}>
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              marginTop={MARGIN.M32}
            >
              Our story
            </Heading4>
            <Text
              fontSize={FONT_SIZE.LARGE}
              marginTop={MARGIN.M16}
              align={ALIGN.LEFT}
            >
              Latitude has a long history of over 100 years dating back to the 1920s when Australian Guarantee Corporation ('AGC'), one of Latitude's predecessor businesses, was founded to provide finance for the purchase of household items. Latitude's history traces back to a range of acquisitions made by GE in Australia and New Zealand, including Nissan Finance, AVCO Financial (including Hallmark Insurance) and AGC.
              <br />
              <br />
              In 2015, backed by Värde Partners, KKR and Deutsche Bank, we transitioned from GE into what we are today — a leading consumer lender called Latitude.
              <br />
              <br />
              In April 2021, Latitude listed on the Australian Stock Exchange. In late 2021, Latitude acquired Symple Loans, a Melbourne-based personal lending fintech.
            </Text>
          </Vertical>
        </div>
      </div>
    </Section>
  );
};

export default OurStorySection;
