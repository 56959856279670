import React from 'react';
import { Heading4 } from '@latitude/heading';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
} from '@latitude/core/utils/constants';

import imageCreateTomorrow from '../../images/values-create-tomorrow.svg';
import imagePursueExcellence from '../../images/values-pursue-excellence.svg';
import imageTakeOwnership from '../../images/values-take-ownership.svg';
import imageWinTogether from '../../images/values-win-together.svg';

const OurValues = () => {
  const ImageGroup = ({ imgSrc, text }) => (
    <div css={`
        text-align: center;
        @media (min-width: ${BREAKPOINT.LG}) {
          max-width: 20%;
        }
      `}
      >
      <img src={imgSrc} alt="take-ownership" />
      <p css="font-size: 16px;">
        {text}
      </p>
    </div>
  )

  return (
    <Section 
      id="our-values"  
      css={`background-color: #F8F8F8;`}
    >
      <div className="row justify-content-center">
        <div className="col-md-10 text-center">
          <Heading4
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            marginTop={MARGIN.M24}
            marginBottom={MARGIN.M8}
          >
            Our values
          </Heading4>
          <Text
            align={ALIGN.LEFT}
            color={COLOR.BLACK}
            marginTop={MARGIN.M32}
            marginBottom={MARGIN.M24}
          >
            Our values guide our actions and decisions, ensuring we deliver the best outcomes ​for our customers, partners, each other and for Latitude.
          </Text>
        </div>
      </div>
      <div css={`
        display: flex;
        justify-content: space-evenly;
        @media (max-width: ${BREAKPOINT.LG}) {
          flex-direction: column;
          align-items: center;
        }
      `}>
        <ImageGroup 
          imgSrc={imageTakeOwnership}
          text="We step into challenges, execute with grit and take accountability."
        />
        <ImageGroup 
          imgSrc={imagePursueExcellence}
          text="We act with integrity to deliver high quality outcomes."
        />
        <ImageGroup 
          imgSrc={imageWinTogether}
          text="We seek different perspectives and unify as one company to win in the market."
        />
        <ImageGroup 
          imgSrc={imageCreateTomorrow}
          text="We think boldly and adapt with agility to innovate and shape the future."
        />
      </div>
    </Section>
  )
};

export default OurValues;
