import React, { useContext } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';

import { BUTTON_STYLE, COLOR, PADDING } from '@latitude/core/utils/constants';
import Accordion from '@/components/Accordion/Accordion';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';

import ManageYourLoan from '@/components/ManageYourLoan/ManageYourLoan';
import Layout from '../../components/layout';
import Metadata from '../../components/Metadata/Metadata';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';

import LifeAtLatitude from './_life-at-latitude';
import OurStory from './_our-story';
import OurValues from './_our-values';
import WhyLatitude from './_why-latitude';
import PageData from './data/careers.json';
import heroImage from '../../images/hero/careers.webp';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import Section from '@latitude/section';
import { Heading4 } from '@latitude/heading';
import { ALIGN, BREAKPOINT, FONT_SIZE, MARGIN } from '@/utils/constants';
import { Text } from '@latitude/text';

const CareerLink = ({text}) => (
  <Link
    variant="secondary"
    href="https://latitudefinancial.wd3.myworkdayjobs.com/careers"
    css={`
      width: fit-content;
      height: 85px;
      margin: 16px auto;
      padding: 0 40px;
      border-radius: 800px;
      background-color: #0555C8 !important;
      color: #FFF;
      font-family: 'Montserrat';
      font-size: 28px;
      font-weight: 700;
    `}
    button={BUTTON_STYLE.SECONDARY}
    data-trackid="callout-find-a-career"
    trackEventData={{
      action: 'careers-link',
      category: 'cta',
      location: 'Find a career'
    }}
  >
    {text}
  </Link>
)

const PlayerWrapper = styled.div`
  position: relative;
  margin: auto;
  padding-top: 170px;
  width: 300px;
  
  @media (min-width: ${BREAKPOINT.SM}) {
    padding-top: 285px;
    width: 500px;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 400px;
    width: 700px;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Careers = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Metadata
          title={PageData.meta.title}
          description={PageData.meta.description}
          canonical="/careers/"
        />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title="Let's Work Together"
            subTitle={
              <>
                We’ve set our sights on helping people from all walks of life to
                shop and live better. So, if you’re someone who wants to do
                amazing work and has a strong desire to make a difference, we’d
                love to chat.
              </>
            }
          />
        )}
        <StickyNavigationBranded
          ctaHref="https://latitudefinancial.wd3.myworkdayjobs.com/careers"
          ctaText="Join the team"
          ctaType={BUTTON_STYLE.SECONDARY}
          offsetElem="[data-sticky-navigation-offset]"
          items={PageData.stickyNavigation}
          trackEventData={{
            action: 'careers-link',
            category: 'cta'
          }}
          trackId="careers-stickynav"
          {...state?.inPageNavData?.[0]}
        />

        <Anchor id="why-latitude" />
        <WhyLatitude />
        
        <Section css={`background-color: #F8F8F8;`}>
          <CareerLink text="Explore Opportunities" />
        </Section>

        <OurValues />

        <Section css={`background-color: #F8F8F8; padding: 40px 0;`}>
          <PlayerWrapper>
            <ReactPlayer
              url={'https://youtu.be/tZ0HehBpYas'}
              className="react-player"
              width="100%"
              height="100%"
              controls
            />
          </PlayerWrapper>
        </Section>

        <OurStory />

        <Anchor id="find-a-career" />
        <Section css={`background-color: #E5F6FF; padding: 80px 0;`}>
          <Heading4
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            marginBottom={MARGIN.M64}
          >
            Find a career
          </Heading4>
          <Text
            fontSize={FONT_SIZE.LARGE}
            marginBottom={MARGIN.M64}
            align={ALIGN.CENTER}
          >
            Find a new career - start the search now.
          </Text>
          <CareerLink css={`margin-bottom: 64px;`} text="Join the Team" />
        </Section>

        <Anchor id="life-at-latitude" />
        <LifeAtLatitude />

        <AnalyticsLocationProvider location="Our benefits">
          <ManageYourLoan heading="Our benefits">
            <Accordion
              titleBgColor="grey-light"
              items={PageData.content.accordionData.map(accordionDataItem => {
                return {
                  id: accordionDataItem.id,
                  title: accordionDataItem.title,
                  content: (
                    <Box padding={PADDING.P16}>
                      {ReactHtmlParser(accordionDataItem.data)}
                    </Box>
                  )
                };
              })}
            />
          </ManageYourLoan>
        </AnalyticsLocationProvider>
      </main>
    </Layout>
  );
};

export default Careers;

const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
`;

const Divider = () => {
  return (
    <hr
      css={`
        width: 80%;
        height: 1px;
        margin: 1px auto;
        background-color: ${COLOR.GREY16};
        color: ${COLOR.GREY16};
        border: 0 none;
      `}
    />
  );
};
